<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                    lg="8"
                    class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"/>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                    lg="4"
                    class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Activate your Cineself account! 🚀
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Use the e-mail that you registered with and your activation code to gain access!
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                                class="auth-login-form mt-2"
                                @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                    label="Email"
                                    label-for="login-email"
                            >
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Email"
                                        rules="required|email"
                                >
                                    <b-form-input
                                            id="login-email"
                                            v-model="form.email"
                                            :state="errors.length > 0 ? false:null"
                                            name="login-email"
                                            placeholder="john@example.com"
                                            :readonly="true"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- First Name -->
                            <b-form-group
                                    label="First Name"
                                    label-for="first-name"
                                    v-if="false"
                            >
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="First Name"
                                        rules="required"
                                >
                                    <b-form-input
                                            id="first-name"
                                            v-model="form.firstName"
                                            :state="errors.length > 0 ? false:null"
                                            name="first-name"
                                            placeholder="John"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- Last Name -->
                            <b-form-group
                                    label="Last Name"
                                    label-for="last-name"
                                    v-if="false"
                            >
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Last Name"
                                        rules="required"
                                >
                                    <b-form-input
                                            id="last-name"
                                            v-model="form.lastName"
                                            :state="errors.length > 0 ? false:null"
                                            name="first-name"
                                            placeholder="Smith"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- Activation code -->
                            <b-form-group
                                :class="mode==='producer'?'':'d-none'"
                                label="Activation code"
                                label-for="activation-code"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Activation Code"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="activation-code"
                                        v-model="form.activationCode"
                                        :state="errors.length > 0 ? false:null"
                                        name="activation-code"
                                        placeholder="AzGXgasdQe..."
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- Password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="password">Choose password</label>
                                </div>
                                <validation-provider v-slot="{ errors }" name="Password" rules="required">
                                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="password" v-model="form.password" :state="errors.length > 0 ? false:null"  class="form-control-merge" :type="passwordFieldType" name="Password" placeholder=""/>
                                        <b-input-group-append is-text>
                                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- Confirm Password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="confirmPass">Confirm Password</label>
                                </div>
                                <validation-provider v-slot="{ errors }" name="Confirm Password" rules="required">
                                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input
                                                id="confirmPass"
                                                v-model="form.confirmPass"
                                                :state="errors.length > 0 ? false:null"
                                                class="form-control-merge"
                                                :type="passwordFieldType"
                                                name="Confirm Password"
                                                placeholder=""
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group class="pb-2">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="agreement with the privacy policy"
                                    rules="required"
                                >
                                    <b-form-checkbox class="custom-control-primary" v-model="privacyPolicy">
                                        I have read and agree with the <b-link href="https://cineself.com/privacy-policy.php">Privacy policy</b-link> of Cineself.
                                    </b-form-checkbox>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit and back buttons -->
                            <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    @click="validationForm"
                            >
                                Activate account
                            </b-button>
                            <b-button
                                    v-if="mode === 'producer'"
                                    type="submit"
                                    variant="outline-primary"
                                    block
                                    @click="goBack()"
                            >
                                Back to Login
                            </b-button>
                        </b-form>
                    </validation-observer>
                    <b-card-text class="text-center mt-2">
                        <span>Want to learn more about Cineself? </span><br/>
                        <span>Visit us at <b-link href="https://cineself.com/" target="_blank">https://cineself.com/</b-link></span>
                    </b-card-text>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BInputGroupAppend,
        BInputGroup,
        BCardText,
        BCardTitle,
        BForm,
        BButton,
    } from 'bootstrap-vue';
    import { required, email } from '@validations';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import store from '@/store/index';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import Parse from 'parse';
    import Api2 from "@/services/api2";

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BInputGroupAppend,
            BInputGroup,
            BCardText,
            BCardTitle,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                mode: 'producer',
                form: {

                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPass: "",
                    activationCode: "",
                },
                loading: false,
                status: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email,
                privacyPolicy: null,
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        mounted() {
            let curUser = Parse.User.current();

            this.form.activationCode = this.$route.query.activationCode;
            this.form.email = this.$route.query.email;
            this.mode = this.$route.query.t ? 'talent' : 'producer';
            this.roleId = this.$route.query.role;

            if(curUser && curUser.attributes.userType === 'producer') {
                this.$router.push({path: '/'});
                //this.$router.push({name: 'talent-landing', params: {roleId: this.roleId}});
            } else if(curUser && curUser.attributes.userType === 'talent') {
                this.$router.push({path: '/talent/'+this.roleId});
            }
        },
        methods: {
            testPassword(password){
                if (password.length < 8)
                    return false;
                //var hasUpperCase = /[A-Z]/.test(password);
                var hasLowerCase = /[a-z]/.test(password);
                var hasNumbers = /\d/.test(password);
                //var hasNonalphas = /\W/.test(password);
                if (hasLowerCase && hasNumbers){
                    return true;
                }else{
                    return false;
                }
            },
            validatePasses(){
                if(this.form.password && this.form.confirmPass && this.testPassword(this.form.password)){
                    if(this.form.password === this.form.confirmPass){
                        return true;
                    }else{
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'The provided passwords do not match.',
                                icon: 'XIcon',
                                variant: 'danger',
                            },
                        });
                        return false;
                    }
                }else{
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Your password is too weak. Use at least 8 characters and include at least one lower case letter and one number.',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    });
                    return false;
                }
            },
            goBack(){
                this.$router.push({name:"login"});
            },
            login() {
                if (!this.loading) {
                    this.loading = true;
                    let self = this;
                    Parse.User.logIn(this.form.email, this.form.password).then((userdata) => {
                        console.log("User data: ", userdata);
                        if(userdata){
                            //self.$root.openToast("Welcome back, "+userdata.attributes.firstName+"!", "secondary");
                            if(self.mode == 'producer'){
                                self.$router.push({name: 'home'});
                            }else {
                                self.$router.push({path: '/talent/'+self.roleId});
                            }

                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Welcome to Cineself, "+userdata.attributes.firstName+"!",
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                        }
                    }).catch(() => {
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Invalid username and/or password!",
                                icon: 'CheckIcon',
                                variant: 'danger',
                            },
                        })
                        //self.$root.openToast("Incorrect username or password!", "danger");
                    });
                }
            },
            validationForm() {
                let self = this;
                this.$refs.loginValidation.validate().then(success => {
                    if (success && self.validatePasses()) {

                        if(self.mode == 'talent') {
                            self.form.isTalent = true;
                        }
                        Api2.sendData('activateProspect',self.form).then(function(){
                            self.login();
                        }).catch(function(error){
                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Error code: " + error.code + " " + error.message,
                                    icon: 'CheckIcon',
                                    variant: 'danger',
                                },
                            });
                        });
                    }else{
                        console.log("Unsuccessful form validation");
                    }
                }).catch(err=> {
                    console.log("Unsuccessful form validation", err.message);
                })
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
