var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"})]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Activate your Cineself account! 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Use the e-mail that you registered with and your activation code to gain access! ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false:null,"name":"login-email","placeholder":"john@example.com","readonly":true},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(false)?_c('b-form-group',{attrs:{"label":"First Name","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","state":errors.length > 0 ? false:null,"name":"first-name","placeholder":"John"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,28387967)})],1):_vm._e(),(false)?_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false:null,"name":"first-name","placeholder":"Smith"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3742720535)})],1):_vm._e(),_c('b-form-group',{class:_vm.mode==='producer'?'':'d-none',attrs:{"label":"Activation code","label-for":"activation-code"}},[_c('validation-provider',{attrs:{"name":"Activation Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"activation-code","state":errors.length > 0 ? false:null,"name":"activation-code","placeholder":"AzGXgasdQe..."},model:{value:(_vm.form.activationCode),callback:function ($$v) {_vm.$set(_vm.form, "activationCode", $$v)},expression:"form.activationCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Choose password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"Password","placeholder":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"confirmPass"}},[_vm._v("Confirm Password")])]),_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPass","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"Confirm Password","placeholder":""},model:{value:(_vm.form.confirmPass),callback:function ($$v) {_vm.$set(_vm.form, "confirmPass", $$v)},expression:"form.confirmPass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"pb-2"},[_c('validation-provider',{attrs:{"name":"agreement with the privacy policy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.privacyPolicy),callback:function ($$v) {_vm.privacyPolicy=$$v},expression:"privacyPolicy"}},[_vm._v(" I have read and agree with the "),_c('b-link',{attrs:{"href":"https://cineself.com/privacy-policy.php"}},[_vm._v("Privacy policy")]),_vm._v(" of Cineself. ")],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" Activate account ")]),(_vm.mode === 'producer')?_c('b-button',{attrs:{"type":"submit","variant":"outline-primary","block":""},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" Back to Login ")]):_vm._e()],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Want to learn more about Cineself? ")]),_c('br'),_c('span',[_vm._v("Visit us at "),_c('b-link',{attrs:{"href":"https://cineself.com/","target":"_blank"}},[_vm._v("https://cineself.com/")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }